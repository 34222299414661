@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: "IBM Plex Sans Thai";
  src: url("../fonts/IBMPlexSansThai-Medium.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "IBM Plex Sans Thai";
  src: url("../fonts/IBMPlexSansThai-SemiBold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "IBM Plex Sans Thai";
  src: url("../fonts/IBMPlexSansThai-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "IBM Flex Sans Thai Loop";
  src: url("../fonts/IBMPlexSansThaiLooped-Regular.ttf");
  font-weight: 400;
}

#container {
  margin: 1rem;
}

.items {
  margin-bottom: 1rem;
}
